<template>
  <div
    v-if="item && Object.keys(item).length && item.image && item.image.length"
    @click="toRoute(item.link)"
    class="area-item"
  >
    <picture v-if="item.image && item.image.length">
      <source
        v-for="(image, ind) in item.image"
        :key="ind"
        :srcset="image.src"
        :media="image.media"
      />
      <img
        class="area-item__image"
        :src="item.image[item.image.length - 1].src"
      />
    </picture>
    <div class="area-item__title" v-html="item.title"></div>
    <div
      class="area-item__nav"
      v-if="fastNav && item.links && item.links.length"
    >
      <div
        @click="toFastNav(item.link, item.title)"
        class="area-item__nav-title"
        v-html="item.title"
      ></div>
      <ul class="area-item__nav-list">
        <li
          class="area-item__nav-item"
          v-for="(navLink, ind) in item.links"
          :key="ind"
        >
          <div
            v-if="navLink"
            @click="toFastNav(navLink.link, item.title)"
            v-html="navLink.title"
            class="area-item__list-link"
          ></div>
        </li>
      </ul>
      <div
        class="area-item__nav-button"
        @click="toFastNav(item.link)"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 12L13.5 24L9.75 24L18.75 13.5L-4.5897e-07 13.5L-5.90104e-07 10.5L18.75 10.5L9.75 -4.26186e-07L13.5 -5.90104e-07L24 12Z"
            fill="#830051"
          />
        </svg>
      </div>
    </div>
    <div class="area-item__button" @click="$router.push({ path: item.link })">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.75 4.5L20.25 12L12.75 19.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="square"
        />
        <path
          d="M18.75 12L3.75 12"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="square"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "AreaItem",
  props: {
    item: Object,
    fastNav: false,
  },
  methods: {
    toRoute(link) {
      if (window.innerWidth < 768) {
        this.toFastNav(link);
      }
    },
    toFastNav(to) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "main materials click", {
          "main materials click": {
            "material name": this.item.title,
             ...this.$root.ymFields
          },
        });
      }
      window.open(to, "_self")
    },
  },
};
</script>

<style lang="scss" scoped>
.area-item {
  padding: 32px;
  position: relative;
  height: 440px;
  display: flex;
  flex-direction: column;

  &__nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    background: rgba(235, 239, 238, 0.95);
    z-index: -10;
    opacity: 0;
    transition: 0.2s linear;
    @media screen and (max-width: 1220px) {
      padding: 24px;
    }

    &-title {
      cursor: pointer;
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #830051;
    }

    &-item {
      cursor: pointer;
      margin-bottom: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
      transition: 0.3s linear;
      @media screen and (max-width: 1220px) {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 22px;
      }
      &:hover {
        color: #d0006f;
      }
    }

    &-button {
      margin-top: auto;
      margin-left: auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: transparent;
      color: #830051;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1220px) {
    height: 387px;
    padding: 24px;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    padding: 0;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 767px) {
      height: 164px;
      position: static;
    }
  }

  &__title {
    position: relative;
    z-index: 2;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      margin-top: 8px;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &__button {
    margin-top: auto;
    margin-left: auto;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #830051;
    border-radius: 999px;
    color: #e6ccdc;
    cursor: pointer;
    transition: 0.2s linear;
    &:hover {
      background-color: #9a3374;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &:hover &__nav {
    @media screen and (min-width: 768px) {
      z-index: 10;
      opacity: 1;
    }
  }

  &:hover &__button {
    background-color: transparent;
  }
}
</style>
